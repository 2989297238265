import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './OfficesTabs.module.scss';
import OurOffices from '../OurOffices';
import GlobalMediaContacts from '../GlobalMediaContacts';
import CorporateContacts from '../CorporateContacts';

// import Breadcrumb from '../Breadcrumb'

const OfficesTabs = ({ tabs, contents, popup }) => {
    const [index, setIndex] = useState(0);
    const handleClick = value => {
        setIndex(value);
        // setCurrentObject(contents[value].contents);
    };
    const currentObject = contents.find(i => i.id === tabs[index].value);

    const renderContent = () => {
        if (currentObject) {
            switch (currentObject.id) {
                case 'our-office':
                    return <OurOffices contents={currentObject.contents} popup={popup} />;
                case 'global-media-contact':
                    return <GlobalMediaContacts contents={currentObject.contents} />;
                case 'corporate-contacts':
                    return <CorporateContacts contents={currentObject.contents} />;
            }
        }
    };

    return (
        <>
            <div className={style.tabWrapper}>
                {/* <Breadcrumb></Breadcrumb> */}
                <div className={[style.container, 'container'].join(' ')}>
                    <div className={style.tabs}>
                        {tabs &&
                            tabs.map(({ title, value }, idx) => (
                                <button
                                    className={[style.tabsValue, idx === index ? style.isActive : ''].join(' ')}
                                    key={idx}
                                    id={value}
                                    onClick={() => handleClick(idx)}
                                >
                                    {title}
                                </button>
                            ))}
                    </div>
                </div>
            </div>
            <div>{renderContent()}</div>
        </>
    );
};

// eslint-disable-next-line no-unused-expressions
OfficesTabs.defaultProps = {
    tabs: [
        {
            title: 'Our office',
            value: 'our-office',
        },
        {
            title: 'Corporate Contacts',
            value: 'corporate-contacts',
        },
        {
            title: 'Global Media Contact',
            value: 'global-media-contact',
        },
    ],
    contents: [
        {
            id: 'our-office',
            contents: {
                title: 'Our Offices',
                regions: [
                    {
                        name: 'Europe',
                        offices: [
                            {
                                name: 'Frankfurt',
                                address: 'Permira Beteiligungsberatung GmbH',
                                tel: '+49 69 97 14 66 0',
                                description: 'The Hong Kong office is led by Alex Emery - Head of Asia.',
                                map: {
                                    lng: 114.1277,
                                    lat: 22.352734,
                                },
                                team: {
                                    title: 'The Hong Kong Team',
                                    cta: {
                                        text: 'Meet the team',
                                        link: {
                                            target: '',
                                            url: '/en',
                                        },
                                    },
                                    members: [
                                        {
                                            id: '0fe14d21-465e-4071-a012-2a155e98092b',
                                            name: 'Tina Smith',
                                            title: 'Principal',
                                            link: {
                                                target: '',
                                                url: '/people/meet-our-people/tina-smith/',
                                            },
                                            image: {
                                                url:
                                                    'http://permira-cm.azurewebsites.net/media/kc3fzg15/unsplash_pata8xe_ivm-3.jpg',
                                                alt: '',
                                            },
                                        },
                                        {
                                            id: '61f2a8dd-a2a2-41b3-8f40-458611604c8b',
                                            name: 'Vincent Van den Bossche',
                                            title: 'Principal',
                                            link: {
                                                target: '',
                                                url: '/people/meet-our-people/vincent-van-den-bossche/',
                                            },
                                            image: {
                                                url:
                                                    'http://permira-cm.azurewebsites.net/media/yovjd0dm/unsplash_pata8xe_ivm.jpg',
                                                alt: '',
                                            },
                                        },
                                        {
                                            id: '0fe14d21-465e-4071-a012-2a155e98092b',
                                            name: 'Tina Smith',
                                            title: 'Principal',
                                            link: {
                                                target: '',
                                                url: '/people/meet-our-people/tina-smith/',
                                            },
                                            image: {
                                                url:
                                                    'http://permira-cm.azurewebsites.net/media/kc3fzg15/unsplash_pata8xe_ivm-3.jpg',
                                                alt: '',
                                            },
                                        },
                                        {
                                            id: '61f2a8dd-a2a2-41b3-8f40-458611604c8b',
                                            name: 'Vincent Van den Bossche',
                                            title: 'Principal',
                                            link: {
                                                target: '',
                                                url: '/people/meet-our-people/vincent-van-den-bossche/',
                                            },
                                            image: {
                                                url:
                                                    'http://permira-cm.azurewebsites.net/media/yovjd0dm/unsplash_pata8xe_ivm.jpg',
                                                alt: '',
                                            },
                                        },
                                        {
                                            id: '61f2a8dd-a2a2-41b3-8f40-458611604c8b',
                                            name: 'Vincent Van den Bossche',
                                            title: 'Principal',
                                            link: {
                                                target: '',
                                                url: '/people/meet-our-people/vincent-van-den-bossche/',
                                            },
                                            image: {
                                                url:
                                                    'http://permira-cm.azurewebsites.net/media/yovjd0dm/unsplash_pata8xe_ivm.jpg',
                                                alt: '',
                                            },
                                        },
                                        {
                                            id: '61f2a8dd-a2a2-41b3-8f40-458611604c8b',
                                            name: 'Vincent Van den Bossche',
                                            title: 'Principal',
                                            link: {
                                                target: '',
                                                url: '/people/meet-our-people/vincent-van-den-bossche/',
                                            },
                                            image: {
                                                url:
                                                    'http://permira-cm.azurewebsites.net/media/yovjd0dm/unsplash_pata8xe_ivm.jpg',
                                                alt: '',
                                            },
                                        },
                                    ],
                                },
                                investments: {
                                    title: 'Recent Investments',
                                    cta: {
                                        text: 'View all',
                                        link: {
                                            target: '',
                                            url: '/en',
                                        },
                                    },
                                    items: [
                                        {
                                            id: 'd290f1ee-6c54-4b01-90e6-d701748f0851',
                                            icon: {
                                                url: 'https://via.placeholder.com/300x300.jpg',
                                                alt: 'image-1',
                                            },
                                            name: 'Vincent Van den Bossche',
                                            link: {
                                                target: '',
                                                url: '/en',
                                            },
                                        },
                                        {
                                            id: 'd290f1ee-6c54-4b01-90e6-d701748f0851',
                                            icon: {
                                                url: 'https://via.placeholder.com/300x300.jpg',
                                                alt: 'image-1',
                                            },
                                            name: 'Vincent Van den Bossche',
                                            link: {
                                                target: '',
                                                url: '/en',
                                            },
                                        },
                                        {
                                            id: 'd290f1ee-6c54-4b01-90e6-d701748f0851',
                                            icon: {
                                                url: 'https://via.placeholder.com/300x300.jpg',
                                                alt: 'image-1',
                                            },
                                            name: 'Vincent Van den Bossche',
                                            link: {
                                                target: '',
                                                url: '/en',
                                            },
                                        },
                                        {
                                            id: 'd290f1ee-6c54-4b01-90e6-d701748f0851',
                                            icon: {
                                                url: 'https://via.placeholder.com/300x300.jpg',
                                                alt: 'image-1',
                                            },
                                            name: 'Vincent Van den Bossche',
                                            link: {
                                                target: '',
                                                url: '/en',
                                            },
                                        },
                                        {
                                            id: 'd290f1ee-6c54-4b01-90e6-d701748f0851',
                                            icon: {
                                                url: 'https://via.placeholder.com/300x300.jpg',
                                                alt: 'image-1',
                                            },
                                            name: 'Vincent Van den Bossche',
                                            link: {
                                                target: '',
                                                url: '/en',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                name: 'Guernsey',
                                address: 'Permira Beteiligungsberatung GmbH',
                                tel: '+49 69 97 14 66 0',
                                description: 'Guernsey description.',
                                map: {
                                    lng: 114.1277,
                                    lat: 22.352734,
                                },
                                team: {
                                    title: 'The Hong Kong Team',
                                    cta: {
                                        text: 'Meet the team',
                                        link: {
                                            target: '',
                                            url: '/en',
                                        },
                                    },
                                    members: [
                                        {
                                            image: {
                                                url: 'https://via.placeholder.com/300x300.jpg',
                                                alt: 'image-1',
                                            },
                                            name: 'Vincent Van den Bossche',
                                            role: 'Principal',
                                            link: {
                                                target: '',
                                                url: '/en',
                                            },
                                        },
                                    ],
                                },
                                investments: {
                                    title: 'Recent Investments',
                                    cta: {
                                        text: 'View all',
                                        link: {
                                            target: '',
                                            url: '/en',
                                        },
                                    },
                                    items: [
                                        {
                                            id: 'd290f1ee-6c54-4b01-90e6-d701748f0851',
                                            icon: {
                                                url: 'https://via.placeholder.com/300x300.jpg',
                                                alt: 'image-1',
                                            },
                                            name: 'Vincent Van den Bossche',
                                            link: {
                                                target: '',
                                                url: '/en',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                name: 'London',
                                address: 'Permira Beteiligungsberatung GmbH',
                                tel: '+49 69 97 14 66 0',
                                description: 'London description.',
                                map: {
                                    lng: 114.1277,
                                    lat: 22.352734,
                                },
                                team: {
                                    title: 'The Hong Kong Team',
                                    cta: {
                                        text: 'Meet the team',
                                        link: {
                                            target: '',
                                            url: '/en',
                                        },
                                    },
                                    members: [
                                        {
                                            image: {
                                                url: 'https://via.placeholder.com/300x300.jpg',
                                                alt: 'image-1',
                                            },
                                            name: 'Vincent Van den Bossche',
                                            role: 'Principal',
                                            link: {
                                                target: '',
                                                url: '/en',
                                            },
                                        },
                                    ],
                                },
                                investments: {
                                    title: 'Recent Investments',
                                    cta: {
                                        text: 'View all',
                                        link: {
                                            target: '',
                                            url: '/en',
                                        },
                                    },
                                    items: [
                                        {
                                            id: 'd290f1ee-6c54-4b01-90e6-d701748f0851',
                                            icon: {
                                                url: 'https://via.placeholder.com/300x300.jpg',
                                                alt: 'image-1',
                                            },
                                            name: 'Vincent Van den Bossche',
                                            link: {
                                                target: '',
                                                url: '/en',
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    {
                        name: 'USA',
                        offices: [
                            {
                                name: 'Frankfurt',
                                address: 'Permira Beteiligungsberatung GmbH',
                                tel: '+49 69 97 14 66 0',
                                link: '/contact-us/hong-kong',
                            },
                        ],
                    },
                    {
                        name: 'Asia',
                        offices: [
                            {
                                name: 'Frankfurt',
                                address: 'Permira Beteiligungsberatung GmbH',
                                tel: '+49 69 97 14 66 0',
                                link: '/contact-us/hong-kong',
                            },
                        ],
                    },
                ],
            },
        },
        {
            id: 'corporate-contacts',
            contents: {
                title: 'Corporate Contacts',
                items: [
                    {
                        image: {
                            url: 'https://via.placeholder.com/300x300.jpg',
                            alt: 'image-1',
                        },
                        name: 'Vincent Van den Bossche',
                        role: 'Principal',
                        link: {
                            target: '',
                            url: '/en',
                        },
                    },
                ],
            },
        },
        {
            id: 'global-media-contact',
            contents: {
                title: 'Global media contacts',
                regions: [
                    {
                        name: 'Europe',
                        offices: [
                            {
                                name: 'Global',
                                contactName: 'Nina Suter',
                                contactRole: 'Head of Communications',
                                tel: '+44 20 7632 1000',
                                emailTitle: 'Email Global Press Office',
                                email: 'joe.chan@landorandfitch.com',
                            },
                        ],
                    },
                    {
                        name: 'USA',
                        offices: [
                            {
                                name: 'USA',
                                contactName: 'Nina Suter',
                                contactRole: 'Head of Communications',
                                tel: '+44 20 7632 1000',
                                emailTitle: 'Email Global Press Office',
                                email: 'joe.chan@landorandfitch.com',
                            },
                        ],
                    },
                    {
                        name: 'Asia',
                        offices: [
                            {
                                name: 'Asia',
                                contactName: 'Nina Suter',
                                contactRole: 'Head of Communications',
                                tel: '+44 20 7632 1000',
                                emailTitle: 'Email Global Press Office',
                                email: 'joe.chan@landorandfitch.com',
                            },
                        ],
                    },
                ],
            },
        },
    ],
};

OfficesTabs.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    contents: PropTypes.array,
    tabs: PropTypes.array,
    popup: PropTypes.bool,
};
export default OfficesTabs;
