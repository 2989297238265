import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import style from './GlobalMediaContacts.module.scss';
import ContactIcon from '../Icons/ContactIcon';
import EmailIcon from '../Icons/EmailIcon';

const GlobalMediaContacts = ({ contents }) => {
    // console.log(contents);
    const { title, regions } = contents;
    const [officeList, setOfficeList] = useState(regions[0].offices);
    // const [currentTitle, setCurrentTitle] = useState(regions[0].name);
    const [index, setIndex] = useState(0);

    return (
        <div className={[style.container, 'container'].join(' ')}>
            <div className={style.officeWrapper}>
                <div className={style.leftItem}>
                    <h2>{title}</h2>
                    <ul className={style.regionWrapper}>
                        {regions.map(({ name, offices }, id) => (
                            <li
                                className={style.regionName}
                                onClick={() => {
                                    setOfficeList(offices);
                                    setIndex(id);
                                    // setCurrentTitle(name);
                                }}
                                key={id}
                            >
                                <span className={['h3', index === id ? style.isActive : ''].join(' ')}>{name}</span>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* region-details */}
                <div className={style.rightItem}>
                    <div className={style.addressCards}>
                        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 2 }}>
                            <Masonry gutter={36}>
                                {officeList.map(({ name, contactName, contactRole, tel, emailTitle, email }, id) => (
                                    <div key={id} className={[`item-${id}`, style.addressCard].join(' ')}>
                                        <h4>{name}</h4>
                                        <div className={style.contactDetails}>
                                            <p className='orangeClr'>{contactName}</p>
                                            <p className='orangeClr'>{contactRole}</p>
                                        </div>
                                        <div className={style.tel}>
                                            <a href={`tel:${tel}`}>
                                                <ContactIcon width='19' height='19' className={style.icon} />
                                                <span>{tel}</span>
                                            </a>
                                        </div>
                                        <div className={style.email}>
                                            <span>{emailTitle}</span>
                                        </div>
                                        <div>
                                            <p className={style.email}>
                                                {email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ? (
                                                    <a href={`mailto:${email}`}>
                                                        <EmailIcon width='19' height='19' className={style.icon} />
                                                        {email}
                                                    </a>
                                                ) : (
                                                    <>
                                                        <EmailIcon width='19' height='19' className={style.icon} />
                                                        {email}
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </div>
                </div>
            </div>
        </div>
    );
};

GlobalMediaContacts.propTypes = {
    name: PropTypes.string,
    contactName: PropTypes.string,
    contactRole: PropTypes.string,
    tel: PropTypes.number,
    emailTitle: PropTypes.string,
    email: PropTypes.string,
    regions: PropTypes.string,
    title: PropTypes.string,
    contents: PropTypes.any,
};
export default GlobalMediaContacts;
