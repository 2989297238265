import * as React from 'react';

function EmailIcon (props) {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M20 4H4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4Z'
                stroke='url(#paint0_linear_787_16343)'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M22 7L13.03 12.7C12.7213 12.8934 12.3643 12.996 12 12.996C11.6357 12.996 11.2787 12.8934 10.97 12.7L2 7'
                stroke='url(#paint1_linear_787_16343)'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <defs>
                <linearGradient
                    id='paint0_linear_787_16343'
                    x1='1.99527'
                    y1='11.9985'
                    x2='21.9946'
                    y2='11.9985'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#3A74B3' />
                    <stop offset='0.9007' stopColor='#272560' />
                    <stop offset='1' stopColor='#272560' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_787_16343'
                    x1='1.99527'
                    y1='9.99743'
                    x2='21.9946'
                    y2='9.99743'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#3A74B3' />
                    <stop offset='0.9007' stopColor='#272560' />
                    <stop offset='1' stopColor='#272560' />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default EmailIcon;
