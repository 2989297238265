import * as React from 'react';

function PinIcon () {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M20 10C20 16 12 22 12 22C12 22 4 16 4 10C4 7.87827 4.84285 5.84344 6.34315 4.34315C7.84344 2.84285 9.87827 2 12 2C14.1217 2 16.1566 2.84285 17.6569 4.34315C19.1571 5.84344 20 7.87827 20 10V10Z'
                stroke='url(#paint0_linear_2408_3052)'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z'
                stroke='url(#paint1_linear_2408_3052)'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <defs>
                <linearGradient
                    id='paint0_linear_2408_3052'
                    x1='3.99622'
                    y1='11.9981'
                    x2='19.9957'
                    y2='11.9981'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#3A74B3' />
                    <stop offset='0.9007' stopColor='#272560' />
                    <stop offset='1' stopColor='#272560' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_2408_3052'
                    x1='8.99858'
                    y1='9.99942'
                    x2='14.9984'
                    y2='9.99942'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#3A74B3' />
                    <stop offset='0.9007' stopColor='#272560' />
                    <stop offset='1' stopColor='#272560' />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default PinIcon;
