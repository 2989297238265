import React from 'react';

import PropTypes from 'prop-types';
import style from './CorporateContacts.module.scss';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import ContactIcon from '../Icons/ContactIcon';

const CorporateContacts = ({ contents }) => {
    const { title, items } = contents;
    return (
        <div className={[style.container, 'container'].join(' ')}>
            <div className={style.officeWrapper}>
                <div className={style.leftItem}>
                    <h2>{title}</h2>
                </div>
                <div className={style.rightItem}>
                    <div className={style.addressCards}>
                        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 2 }}>
                            <Masonry gutter={36}>
                                {items.map((item, id) => {
                                    return (
                                        <div key={id} className={`item-${id}`}>
                                            <h4 className='orangeClr'>{item.title}</h4>
                                            <div>
                                                <p className={style.email}>
                                                    <ContactIcon width='19' height='19' className={style.icon} />
                                                    {item.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ? (
                                                        <a href={`mailto:${item.email}`}>{item.email}</a>
                                                    ) : (
                                                        <>{item.email}</>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Masonry>
                        </ResponsiveMasonry>
                    </div>
                </div>
            </div>
        </div>
    );
};
CorporateContacts.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.string,
    role: PropTypes.string,
    contents: PropTypes.any,
    items: PropTypes.string,
};

export default CorporateContacts;
