import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';
import style from './CommonStaticHeader.module.scss';

const CommonStaticHeader = props => {
    const { title, description, image, mobileImage } = props;

    const isMobile = useMediaQuery('only screen and (max-width: 650px)');

    const useMobileImage = mobileImage?.url.length > 1 ? mobileImage.url : image?.url;

    return (
        <div>
            <div className={style.section}>
                <div
                    className={style.staticBanner}
                    style={{
                        backgroundImage: `url(${isMobile ? useMobileImage : image.url})`,
                        backgroundSize: 'cover',
                    }}
                >
                    <div className={style.heroBannerWrapper}>
                        <div className={style.bannerTop}>
                            <div className={[style.container, 'container'].join(' ')}>
                                <h1>{title}</h1>
                            </div>
                        </div>
                        <div className={[style.container, style.bannerBottom, 'container'].join(' ')}>
                            {description && (
                                <div
                                    className={style.bannerDesc}
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                />
                            )}
                        </div>
                        {/* <div className={style.bannerDesc}><p>lorem ipsum</p></div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

CommonStaticHeader.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    mobileImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
};
export default CommonStaticHeader;

CommonStaticHeader.defaultProps = {
    title: 'Contact Us',
    image: {
        url: 'https://via.placeholder.com/300x300.jpg',
        alt: 'image-1',
    },
    mobileImage: {
        url: 'https://via.placeholder.com/300x300.jpg',
        alt: 'image-1',
    },
};
