import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SmartLink } from '../SmartLink';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import style from './OurOffices.module.scss';
import PinIcon from '../Icons/PinIcon';
import ContactIcon from '../Icons/ContactIcon';

const OurOffices = ({ contents, relatedOffices }) => {
    const { title, regions } = contents;
    const [index, setIndex] = useState(0);
    const [officeList, setOfficeList] = useState([...regions[0].offices]);

    return (
        <div className={[style.container, 'container', relatedOffices ? style.relatedOffices : ''].join(' ')}>
            <div className={style.officeWrapper}>
                {/* name */}
                <div className={style.leftItem}>
                    <h2>{title}</h2>
                    {regions[0].name && (
                        <ul className={style.regionWrapper}>
                            {regions.map(({ name, offices }, id) => (
                                <li
                                    className={style.regionName}
                                    onClick={() => {
                                        setIndex(id);
                                        setOfficeList(offices);
                                    }}
                                    key={id}
                                >
                                    <span className={['h3', index === id ? style.isActive : ''].join(' ')}>{name}</span>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                {/* region-details */}
                <div className={style.rightItem}>
                    <div className={style.addressCards}>
                        {!relatedOffices && (
                            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 2 }}>
                                <Masonry gutter={36}>
                                    {officeList.map(({ name, subtitle, address, tel, link }, idx) => (
                                        <div key={idx} className={[`item-${idx}`, style.addressCard].join(' ')}>
                                            <h4>
                                                {name}
                                                {subtitle && (
                                                    <>
                                                        <br />
                                                        <span>{subtitle}</span>
                                                    </>
                                                )}
                                            </h4>
                                            <div className={style.address}>
                                                <PinIcon />
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: address,
                                                    }}
                                                />
                                            </div>
                                            {tel.value && (
                                                <p className={style.tel}>
                                                    <ContactIcon />
                                                    <a href={`tel:${tel.value}`}>
                                                        {tel.title} {tel.value}
                                                    </a>
                                                </p>
                                            )}
                                            {link && (
                                                <div className={style.gotoLink}>
                                                    <SmartLink href={link.link.url} className='arrow-link' hasArrow>
                                                        {link.text}
                                                    </SmartLink>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </Masonry>
                            </ResponsiveMasonry>
                        )}
                        {relatedOffices && (
                            <div className={style.officeGrid}>
                                {officeList.map(({ name, subtitle, address, tel, link }, idx) => (
                                    <div key={idx} className={[`item-${idx}`, style.addressCard].join(' ')}>
                                        <h4>
                                            {name}
                                            {subtitle && (
                                                <>
                                                    <br />
                                                    <span>{subtitle}</span>
                                                </>
                                            )}
                                        </h4>
                                        <div
                                            className={style.address}
                                            dangerouslySetInnerHTML={{
                                                __html: address,
                                            }}
                                        />
                                        {tel.value && (
                                            <p className={style.tel}>
                                                <a href={`tel:${tel.value}`}>
                                                    {tel.title} {tel.value}
                                                </a>
                                            </p>
                                        )}
                                        {link && (
                                            <div className={style.gotoLink}>
                                                <SmartLink href={link.link.url} className='arrow-link' hasArrow>
                                                    {link.text}
                                                </SmartLink>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

OurOffices.propTypes = {
    title: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    mobileImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    contents: PropTypes.any,
    popup: PropTypes.bool,
    relatedOffices: PropTypes.bool,
};
export default OurOffices;
